import * as React from "react";
import { Link } from "gatsby";
import "twin.macro";

import SEO from "../components/seo";
import Layout from "../components/layout";

export default function NotFoundPage() {
  return (
    <Layout>
      <SEO title="Seite existiert nicht" />
      <div tw="container px-6 mx-auto py-24 text-center">
        <h1 tw="text-4xl font-medium text-gray-400">
          404 <span tw="text-gray-200">|</span> Not found
        </h1>
        <Link
          tw="text-lg hover:bg-opacity-90 py-3 px-6 mt-8 rounded-md inline-flex items-center font-medium bg-primary text-white"
          to="/"
        >
          Geh zurück
        </Link>
      </div>
    </Layout>
  );
}
